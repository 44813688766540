export default {
  AUTH: {
    authorization_code: 'auth/wallet/authorization-code',
    generate_token: 'auth/wallet/token',
    mint_nft: 'auth/mint-client',
    connect_wallet: 'connect-wallet',
  },
  NFT: {
    get_list_nft: 'auth/tokens',
    get_detail_nft: 'auth/token',
    history_transfer: 'auth/history-purchase-token',
    history_rental: 'auth/history-rent-token',
  },
  CATALOG: {
    get_list_type_heros: 'auth/type-heros',
    get_list_filter_config: 'auth/filter-config',
  },
  STAKE: {
    get_list: 'auth/blockchain/list-pool',
  },
  GAME: {
    get_all_game: 'public/games',
    game_servers: 'game-servers',
    game_properties: 'public/game-properties',
  },
  USER: {
    forgot_password: 'public/user/forgot-password',
    register: 'public/user/register',
    report_hack: 'public/user/report-hack',
    report_hack_confirm: 'public/user/report-hack/confirm',
    reset_password: 'public/user/reset-password',
    verify_email_confirm: 'public/user/verify-email/confirm',
    verify_emai: 'user/verify-email',
    userinfo: 'userinfo',
    resent_verify_email: 'public/user/resent-verify-email',
    user: '/user',
  },
  USER_AUTH: {
    token: 'public/auth/token',
    user_info: 'userinfo',
    change_pass: 'user/my-password',
    delete_account: 'user/my-account',
  },
  GAME_FEED: {
    feeds: 'public/feeds',
    feed: 'feed',
    comment_like: 'feed/comment',
    list_game_info: 'public/games',
    list_comment: 'feed/comments',
    forbidden_words: 'public/forbidden-words',
    load_comment: 'public/feed',
  },
  BANNER: {
    get_all_banner: 'public/banners',
  },
  ASSET: {
    get_asset_sale: 'public/asset',
    my_asset: 'my-asset',
    sale_unlisting: 'asset/sale-unlisting',
    sale_listing: 'asset/sale-listing',
    transaction_histories: 'public/asset/transaction-histories',
    element_config: 'public/asset/element-config',
    get_asset_heroes_by_game: 'asset-heroes/swap',
    with_list: 'wish-list',
    fee_market: 'public/market/fee-market',
  },
  GAME_CHARACTER: {
    get_game_characters: 'my-game-characters',
    gift_code: 'gift-code',
  },
  PACKAGE_CONFIG: {
    package_configs: '/package-configs',
    my_top_up_histories: 'my-top-up-histories',
  },
  BALANCE: {
    balances: '/balances',
  },
  CURRENCIES: {
    currencies: 'public/currencies',
  },
  SITE_CONFIG: {
    site_config: 'public/site-config',
  },
  UPLOAD_FILE: {
    upload_file: 'resource/upload',
    upload_multiple_files: 'admin/upload/uploadMultipleFiles',
  },
  LOYALTY: {
    balance_loyalty: 'balance-loyalty',
    current_vip: 'current-vip',
    vip_config: 'vip-config',
    current_ref_quest: 'current-ref-quest',
    summary_ref_bonus_info: 'summary-ref-bonus-info',
    summary_ref_bonus_info_detail: 'summary-ref-bonus-info/detail',
    ref_reward_config: 'ref-reward-config',
  },
  NOTIFICATION: {
    notification: 'notification',
    read_all: 'notification/mark-as-read-all',
    count_unread: 'notification/count-unread',
  },
};
