import listUrl from '@networking/url/listUrl';
import SsoClient from '@networking/clients/SsoClient';
import AppCms from '@networking/clients/AppCms';
import GateWayClient from '@networking/clients/GateWayClient';
import SecureHelper from '@core/helpers/SecureHelper';
import WalletClient from '@networking/clients/WalletClient';
import LoyaltyClient from '@networking/clients/LoyaltyClient';
import { ParamsRegisterForm, ParamsResetPassword } from './types';

export default {
  login: (data: any, params: any) => {
    return new Promise((resolve, reject) => {
      SsoClient.post({
        url: listUrl.USER_AUTH.token,
        data,
        config: {
          isKeepRequest: true,
          isEncrypt: false,
        },
        params,
      })
        .then((res: any) => {
          SsoClient.setAuthorization(res.data);
          AppCms.setAuthorization(res.data);
          GateWayClient.setAuthorization(res.data);
          WalletClient.setAuthorization(res.data);
          LoyaltyClient.setAuthorization(res.data);
          SecureHelper.setPrivateKey(res.data.sk);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getUserInfo: () => {
    return new Promise((resolve, reject) => {
      SsoClient.get({
        url: listUrl.USER_AUTH.user_info,
        config: {
          isCheckAuth: true,
        },
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  register: (data: ParamsRegisterForm) => {
    return new Promise((resolve, reject) => {
      SsoClient.post({
        url: listUrl.USER.register,
        data,
        config: {
          isKeepRequest: true,
          // isEncrypt: false,
        },
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  forgotPassword: (params: any) => {
    return new Promise((resolve, reject) => {
      SsoClient.put({
        url: listUrl.USER.forgot_password,
        config: {
          isKeepRequest: true,
        },
        params,
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  changeMyPassword: (data: any) => {
    return new Promise((resolve, reject) => {
      SsoClient.put({
        url: listUrl.USER_AUTH.change_pass,
        data,
        config: {
          isCheckAuth: true,
          isKeepRequest: true,
        },
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deleteAccount: (data: any) => {
    return new Promise((resolve, reject) => {
      SsoClient.put({
        url: listUrl.USER_AUTH.delete_account,
        data,
        config: {
          isCheckAuth: true,
          isKeepRequest: true,
        },
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  resetPassword: (data: ParamsResetPassword) => {
    return new Promise((resolve, reject) => {
      SsoClient.post({
        url: listUrl.USER.reset_password,
        config: {
          isKeepRequest: true,
        },
        data,
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
